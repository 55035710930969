


























































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import BreadCrumbs from '@/components/common/BreadCrumbs.vue'
import Cookie from 'js-cookie'
import { getSessionStorage } from '@/utils/storage'
import {
  getCenterList,
  getStatistics,
  getOverdue
} from '@/api/modules/project'
@Component({
  components: {
    BreadCrumbs
  }
})
export default class extends Vue {
  private select: Number =Number(Cookie.get('centerId')) || 0;
  private options: object = [];
  private allTotal: Number = 0;
  private going: Number = 0;
  private completed: Number = 0;
  private falloff: Number = 0;
  private tableHeader: object = [
    { text: '序号', slot: 'index' },
    { text: '受试者编号', field: 'patientNumber' },
    { text: '中心名称', field: 'centerName' },
    { text: '当前随访期', field: 'visitDate', width: '150' },
    { text: '问卷名称', field: 'questionnaireName' },
    { text: '状态', slot: 'status' },
    { text: '访视窗剩余时间', slot: 'visitLeft' },
    { text: '下个访视日期', field: 'nextVisitDate', width: '150' }
  ];
  private tableData: object = [];
  private page: any = {
    currentPage: 1,
    pageSize: 10
  };
  private total: number = 0;
  private Qstatus: any = {
    0: '全部',
    1: '未到填写时间',
    2: '未填写',
    3: '已填写',
    4: '已逾期'
  };
  private loading = true;

  get projectName (): number {
    return getSessionStorage('projectName')
  }
    @Watch('select')
  OnchangeSelect (newVal: number) {
    Cookie.set('centerId', Number(newVal))
    this.page.currentPage = 1
    this.page.pageSize = 10
    this.getPatientStatistics()
    this.getOverdueTable(1, 10)
  }
    created () {
    // 中心列表
      this.getCenter()
      // 受试者统计信息
      this.getPatientStatistics()
      // 获得已预期的问卷
      this.getOverdueTable(1, 10)
    }

    // 获得中心列表
    private getCenter () {
      let params = {
        userId: Cookie.get('id'),
        projectId: Cookie.get('projectId'),
        currentPage: this.page.currentPage,
        pageSize: this.page.pageSize
      }
      getCenterList(params).then((res: any) => {
        if (res.data.list.length > 0) {
          this.options = [{ id: 0, name: '全部', code: '' }, ...res.data.list]
        }
        this.select = Number(Cookie.get('centerId')) || 0
      })
    }
    // 分页
    private changePage (value: number): void {
      this.page.currentPage = value
      this.getOverdueTable(value, this.page.pageSize)
    }
    private changeSize (value: number): void {
      this.page.pageSize = value
      this.getOverdueTable(this.page.currentPage, value)
    }
    // 获得受试者统计信息
    private getPatientStatistics () {
      let data = {
        userId: Cookie.get('id'),
        projectId: Cookie.get('projectId'),
        centerId: this.select
      }
      getStatistics(data).then((res: any) => {
        this.allTotal = res.data.total
        this.going = res.data.going
        this.completed = res.data.completed
        this.falloff = res.data.fallOff
      })
    }
    // 获得已逾期的问卷
    private getOverdueTable (currentPage:number, pageSize:number) {
      let data = {
        userId: Cookie.get('id'),
        projectId: Cookie.get('projectId'),
        centerId: this.select,
        currentPage: currentPage,
        pageSize: pageSize
      }
      getOverdue(data).then((res: any) => {
        if (res.code === 0) {
          this.tableData = res.data.list.map((value: any) => {
            return {
              ...value,
              status: this.Qstatus[value.status],
              visitLeft: '剩余' + value.visitLeft + '天'
            }
          })
          this.total = res.data.total
          this.page.currentPage = res.data.currentPage
        }
      })
    }
}
